import { MDBContainer, MDBRow } from 'mdbreact'

import DataMapLocations from '../components/gm/content'
import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

const FindLocations_export = ({ location, data }) => {
  const allNodeCenter = data.allNodeCenter.edges

  return (
    <Layout>
      <SEO title="Find Locations Export" />
      <main>
        <table
          css={css`
            margin: 5%;
            border: 1px solid black;

            tbody tr:nth-child(odd) {
              background-color: #eeeeee;
            }
            tbody tr:nth-child(even) {
              background-color: #ffffff;
            }

            td,
            th {
              border: 1px solid black;
              border-collapse: collapse;
              padding: 0.2rem;
            }
          `}
        >
          <thead>
            <th>Location</th>
            <th>Export</th>
          </thead>
          <tbody>
            {allNodeCenter.map((item, key) => {
              return (
                <tr>
                  <td>
                    <a target="_blank" href={item.node.path.alias}>
                      {item.node.title}
                    </a>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={`${item.node.path.alias}/export_structured`}
                    >
                      [Export]
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </main>
    </Layout>
  )
}

export default FindLocations_export

export const query = graphql`
  query Findlocations_export {
    allNodeCenter(
      filter: {
        # delete 2 when remove kys
        field_center_group: { in: [0, 1, 2] }
        status: { eq: true }
      }
    ) {
      edges {
        node {
          title
          field_latitude
          field_longitude
          path {
            alias
          }
          field_address_fl
          field_city
          field_state
          field_zip
          field_center_group
        }
      }
    }
  }
`
